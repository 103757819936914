import React, { useState } from 'react';
import QRCode from 'qrcode.react';

function QRCodeGenerator() {
  const [qrValue, setQRValue] = useState(''); // Valor para el código QR
  const [showQR, setShowQR] = useState(false);

  const handleInputChange = (e) => {
    setQRValue(e.target.value);
    setShowQR(true);
  };

  const handleDownloadClick = () => {
    const canvas = document.querySelector('canvas');
    const url = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = url;
    link.download = 'codigo_qr.png';
    link.click();
  };

  return (
    <div className="general-container" style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/bg.png)"}}>
            <h1>QR Generator</h1>
            <div className="card">

                <label>Por favor, ingrese el url:</label>
                <input
                  type="text"
                  placeholder="Texto para el código QR"
                  value={qrValue}
                  onChange={handleInputChange}
                />
                {showQR? 
                <QRCode value={qrValue} size={300} />
                :''}
                <button onClick={handleDownloadClick}>Descargar Código QR</button>
            </div>
            
        </div>
  );
}

export default QRCodeGenerator;