import logo from './logo.svg';
import './App.css';
import QRGenerator from './QRGenerator';
import QRCodeGenerator from './QRCodeGenerator';


function App() {
  return (
    <div className="App">
      <img className="header-logo" src={process.env.PUBLIC_URL+"/images/logo.png"} alt="logo"/>
      <QRCodeGenerator/>
    </div>
  );
}

export default App;
